<script>
    import { inertia } from '@inertiajs/svelte';

    export let items = [
        {
            label: 'Referral Market',
            url: '/markets',
        },
        {
            label: 'Referral Claim',
            url: '/markets/referralclaim',
        },
        {
            label: 'Post Market',
            url: '/markets/postmarket',
        },
        {
            label: 'My Market',
            url: '/markets/my/offers',
        },
    ];

    export let activeTabValue = window.location.pathname;
</script>

<div class="col-12 border-bottom pr-0 pt-4">
    <div class="px-3 pb-4">
        <nav class="marketPlaceTabs -mb-px flex space-x-8" aria-label="Tabs">
            {#each items as item}
                <a
                    id="pills-home-tab"
                    class="{item.url !== '/markets/my/offers'
                        ? activeTabValue === item.url
                            ? 'cursor-pointer whitespace-nowrap border-b-2 border-l-0 border-r-0 border-t-0 border-solid border-indigo-500 px-1 py-2 text-base font-semibold text-indigo-600'
                            : 'cursor-pointer whitespace-nowrap border-b-2 border-l-0 border-r-0 border-t-0 border-solid border-transparent px-1 py-2 text-base font-semibold text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        : activeTabValue.includes('my')
                          ? 'cursor-pointer whitespace-nowrap border-b-2 border-l-0 border-r-0 border-t-0 border-solid border-indigo-500 px-1 py-2 text-base font-semibold text-indigo-600'
                          : 'cursor-pointer whitespace-nowrap border-b-2 border-l-0 border-r-0 border-t-0 border-solid border-transparent px-1 py-2 text-base font-semibold text-gray-500 hover:border-gray-300 hover:text-gray-700'}"
                    href="{item.url}"
                    use:inertia
                >
                    {item.label}
                </a>
            {/each}
        </nav>
    </div>
</div>
